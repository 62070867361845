/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { ProductNormsText } from '../../../../product/components/psaNorms';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    item: VariantItem;
};

export const ItemExpiredDetails: React.FC<Props> = ({ item }) => (
    <div css={styles.details}>
        {item.variantKey}
    </div>
);

const styles = {
    details: css`
        font-family: 'Optima LT Pro DemiBold', sans-serif;
        font-size: ${fontSizes.xMedium};
        line-height: 18px;
        text-transform: uppercase;
    `,
};
