/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';
import { Col, Row } from 'antd';
import { FirstColumn, FirstColumnProps } from './FirstColumn';
import { SecondColumn, SecondColumnProps } from './SecondColumn';
import { LastColumn, LastColumnProps } from './LastColumn';

type Props = FirstColumnProps & SecondColumnProps & LastColumnProps;

export const ProductDetails: React.FC<Props> = ({
    rotationRates,
    handleRotationChange,
    availableColors,
    onColorChange,
    colorWarning,
    availableWeights,
    onWeightChange,
    weightWarning,
    handleGlobalItemsPerWearerChange,
    handleGlobalAmountOfWearersChange,
    handleTotalPriceChange,
    handleWeeklyTotalPriceAllItemsChange,
    offerServices,
    selectedServices,
    setSelectedServices,
    initialTermsAndConditions,
    setTermsAndConditions,
    onKmuEdit,
}) => (
    <Row gutter={[32, 0]} css={styles.wrapper}>
        <Col span={8}>
            <div css={styles.container} data-test={'productDetails1stCol'}>
                <FirstColumn
                    rotationRates={rotationRates}
                    handleRotationChange={handleRotationChange}
                    availableColors={availableColors}
                    onColorChange={onColorChange}
                    colorWarning={colorWarning}
                    availableWeights={availableWeights}
                    onWeightChange={onWeightChange}
                    weightWarning={weightWarning}
                    handleGlobalItemsPerWearerChange={handleGlobalItemsPerWearerChange}
                    handleGlobalAmountOfWearersChange={handleGlobalAmountOfWearersChange}
                />
            </div>
        </Col>

        <Col span={8}>
            <div css={styles.container} data-test={'productDetails2ndCol'}>
                <SecondColumn
                    offerServices={offerServices}
                    selectedServices={selectedServices}
                    setSelectedServices={setSelectedServices}
                    handleTotalPriceChange={handleTotalPriceChange}
                    handleWeeklyTotalPriceAllItemsChange={handleWeeklyTotalPriceAllItemsChange}
                />
            </div>
        </Col>

        <Col span={8}>
            <div css={styles.container} data-test={'productDetails3rdCol'}>
                <LastColumn
                    initialTermsAndConditions={initialTermsAndConditions}
                    setTermsAndConditions={setTermsAndConditions}
                    onKmuEdit={onKmuEdit}
                />
            </div>
        </Col>
    </Row>
);

const styles = {
    wrapper: css`
        margin-top: 78px;
    `,
    container: css`
        border-radius: 5px;
        background-color: ${colors.background};
        margin-top: 16px;
        padding: 32px;
        height: calc(100% - 16px);
    `,
};
