/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { ProductNormsText } from 'src/modules/product/components/psaNorms';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    item: VariantItem;
};

export const ModalItemDetails: React.FC<Props> = ({ item }) => (
    <div css={styles.details}>
        <div css={styles.name}>
            {item.expired ? item.variantKey : item.modelName}
        </div>

        {
            !item.expired &&
            <div>{item.variantMaterial}</div>
        }

        {
            !item.expired &&
            <div>
                {item.variantColor.name} / {item.variantKey} / {item.variantWeight}
                <span css={styles.weightUnits}>
                    g/m<sup>2</sup>
                </span>
            </div>
        }

        {item.norms?.length ? <ProductNormsText norms={item.norms} /> : null}
    </div>
);

const styles = {
    details: css`
        font-family: 'Optima LT Pro DemiBold', sans-serif;
        font-size: ${fontSizes.xxSmall};
        line-height: 18px;
        text-transform: capitalize;
    `,
    name: css`
        font-weight: 700;
        font-size: ${fontSizes.mSmall};
        text-transform: capitalize;
    `,
    weightUnits: css`
        text-transform: lowercase;
        padding-left: 5px;
    `,
    icon: css`
        margin-left: 4px;
        svg {
            fill: ${colors.success};
        }
    `,
};
