import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';
import { AdditionalArticleItem } from './additionalArticleItem';
import { AdditionalItem } from '../../../../../helpers/types';

const styles = StyleSheet.create({
    productList: {
        minWidth: '100%',
        width: '100%',
        marginTop: '32px',
    },
    productListHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        fontSize: '6.5px',
        borderBottom: `0.5px solid ${colors.secondaryDark}`,
        fontFamily: 'GebauerMedium',
    },
    productListTableCol34: {
        flexBasis: '66.5%',
        maxWidth: '66.5%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    productListNormalTableCol: {
        flexBasis: 'calc(10.5% - 4px)',
        maxWidth: 'calc(10.5% - 4px)',
        padding: '2px',
        textAlign: 'right',
        paddingBottom: '8px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    productListTableCol1: {
        flexBasis: 'calc(5% - 4px)',
        maxWidth: 'calc(5% - 4px)',
        padding: '2px',
        textAlign: 'left',
        paddingBottom: '8px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    subheading: {
        fontSize: '9.5px',
        fontFamily: 'GebauerBold',
        color: colors.darkRed,
    },
});

type Props = {
    items: AdditionalItem[];
    startingPos: number;
};

export const AdditionalArticlesList: React.FC<Props> = ({ items, startingPos }) => (
    <View style={styles.productList} wrap={false}>
        <Text style={styles.subheading}>Zusatzartikel</Text>

        <View style={styles.productListHeader} fixed>
            <View style={styles.productListTableCol1}>
                <Text>Pos.</Text>
            </View>
            <View style={{ ...styles.productListNormalTableCol, textAlign: 'left' }}>
                <Text>Artikel</Text>
            </View>
            <View style={styles.productListTableCol34}></View>
            <View style={styles.productListNormalTableCol}>
                <Text>Menge</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Preis pro</Text>
                <Text>Woche</Text>
                <Text>je Artikel</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Preis pro</Text>
                <Text>Woche je</Text>
                <Text>einzukl. Person</Text>
            </View>
        </View>

        <View>
            <AdditionalArticleItem item={items[0]} pos={startingPos} />
        </View>
    </View>
);
