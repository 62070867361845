/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';
import { parseToPrice } from 'src/helpers/utils';

type Props = {
    price?: number;
    bold?: boolean;
    last?: boolean;
};

export const ItemElementPriceCell: React.FC<Props> = ({ price, bold, last }) => (
    <div
        css={[
            itemElementPriceCellStyle.section,
            itemElementPriceCellStyle.price,
            bold && itemElementPriceCellStyle.bold,
            last && itemElementPriceCellStyle.lastPriceElement,
        ]}
    >
        {price ? `${parseToPrice(price || 0)} €` : '-'}
    </div>
);

export const itemElementPriceCellStyle = {
    bold: css`
        font-weight: 700;
    `,
    section: css`
        background-color: ${colors.primary};
        height: 100%;
        display: flex;

        align-items: center;
        font-size: ${fontSizes.mSmall};
        font-family: 'Optima LT Pro Medium', sans-serif;
        color: ${colors.fontHeader};

        justify-content: flex-end;
        text-align: right;
    `,
    lastPriceElement: css`
        padding-right: 4px;
    `,
    price: css`
        align-self: center;
        font-size: ${fontSizes.mSmall};
        font-family: OptimaLTPro-Medium;
        min-width: 70px;
    `,
};
