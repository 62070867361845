/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Header } from 'src/components';
import { colors, fontSizes } from 'src/constants/variables';
import { Col, Row } from 'antd';
import { ColumnTitle } from '../../Offer/ProductRow/ColumnTitle';
import { ItemsList } from './ItemsList';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    items: VariantItem[];
};

export const ItemsArea: React.FC<Props> = ({ items }) => (
    <div>
        <Header css={styles.sectionHeader}>Ihre Artikelauswahl</Header>
        <h3 css={styles.subheading}>Outfit</h3>
        <Row>
            <Col span={1}>
                <ColumnTitle title={["Pos."]} first />
            </Col>
            <Col span={9} css={styles.articleHeader}>
                <ColumnTitle title={["Artikel"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Einzukl.", "Personen/", "Menge"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Ausstattung"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Preis pro", "Woche", "je Artikel"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Brustlogo"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Rückenlogo"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Namensschild"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Preis pro", "Woche je", "einzukl. Person"]} />
            </Col>
        </Row>

        <div css={styles.itemsList}>
            <ItemsList items={items.sort((a, b) => a.offerItemId! - b.offerItemId!)} />
        </div>
    </div>
);

const styles = {
    sectionHeader: css`
        text-transform: none;
        color: ${colors.newOfferTitle};

        :before {
            background-color: ${colors.fontHeaderSecondary};
        }

        & {
            margin-top: 0;
            margin-left: 0;
        }
    `,
    articleHeader: css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 22px;
    `,
    itemsList: css`
        border-top: 1px solid ${colors.backgroundDark}40;
        border-bottom: 1px solid ${colors.backgroundDark}40;
        margin-bottom: 16px;
    `,
    customizedItemsNote: css`
        font-size: ${fontSizes.mSmall};
        font-family: 'Optima LT Pro Medium', sans-serif;
    `,
    subheading: css`
        color: ${colors.fontHeader};
        font-family: OptimaLTPro-Roman;
        font-size: 22px;
    `,
};
